import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useStatusProvider } from './StatusProvider';
import { useImmutableProvider } from './ImmutableProvider';
import { ethers } from 'ethers';
import { STORAGE, getLocalStorage, setLocalStorage, removeLocalStorage } from '../utils/storage';
import { MobXProviderContext } from 'mobx-react';
import { useAuth } from '../hooks/auth';
import { LOGIN_TYPE } from '../constants/common';

const PassportContext = createContext({
  imxProvider: undefined,
  zkEvmProvider: undefined,
  connectImx: () => undefined,
  connectZkEvm: () => undefined,
  logout: () => undefined,
  login: () => undefined,
  getIdToken: () => Promise.resolve(undefined),
  getAccessToken: () => Promise.resolve(undefined),
  getUserInfo: () => Promise.resolve(undefined),
  getLinkedAddresses: () => Promise.resolve(undefined),
  linkWallet: () => Promise.resolve(undefined),
  getSigner: () => Promise.resolve(undefined),
});

export function PassportProvider({ children }) {
  const [imxProvider, setImxProvider] = useState();
  const [zkEvmProvider, setZkEvmProvider] = useState();
  const { addMessage, setIsLoading } = useStatusProvider();
  const { passportClient, setPassportClient } = useImmutableProvider();
  const { auth } = useContext(MobXProviderContext);
  const { onLoginAction } = useAuth();

  const connectImx = useCallback(async () => {
    try {
      setIsLoading(true);
      const provider = await passportClient.connectImx();
      if (provider) {
        setImxProvider(provider);
        addMessage('ConnectImx', 'Connected');
      } else {
        addMessage('ConnectImx', 'Failed to connect');
      }
    } catch (err) {
      addMessage('ConnectImx', err.message || 'Error');
    } finally {
      setIsLoading(false);
    }
  }, [passportClient, setIsLoading, addMessage]);

  const connectZkEvm = useCallback(async () => {
    setIsLoading(true);
    try {
      const provider = await passportClient.connectEvm();
      if (provider) {
        setZkEvmProvider(provider);
        addMessage('ConnectZkEvm', 'Connected');
      } else {
        addMessage('ConnectZkEvm', 'Failed to connect');
      }
    } catch (err) {
      addMessage('ConnectZkEvm', err.message || 'Error');
    } finally {
      setIsLoading(false);
    }
  }, [passportClient, setIsLoading, addMessage]);

  const getIdToken = useCallback(async () => {
    setIsLoading(true);
    try {
      const idToken = await passportClient.getIdToken();
      addMessage('Get ID token', idToken);
      return idToken;
    } catch (err) {
      addMessage('Get ID token', err.message || 'Error');
      return undefined;
    } finally {
      setIsLoading(false);
    }
  }, [passportClient, setIsLoading, addMessage]);

  const getAccessToken = useCallback(async () => {
    setIsLoading(true);
    try {
      const accessToken = await passportClient.getAccessToken();
      addMessage('Get Access token', accessToken);
      return accessToken;
    } catch (err) {
      addMessage('Get Access token', err.message || 'Error');
      return undefined;
    } finally {
      setIsLoading(false);
    }
  }, [passportClient, setIsLoading, addMessage]);

  const getUserInfo = useCallback(async () => {
    setIsLoading(true);
    try {
      const userInfo = await passportClient.getUserInfo();
      addMessage('Get User Info', userInfo);
      return userInfo;
    } catch (err) {
      addMessage('Get User Info', err.message || 'Error');
      return undefined;
    } finally {
      setIsLoading(false);
    }
  }, [passportClient, setIsLoading, addMessage]);

  const getLinkedAddresses = useCallback(async () => {
    setIsLoading(true);
    try {
      const linkedAddresses = await passportClient.getLinkedAddresses();
      addMessage('Get Linked Addresses', linkedAddresses);
      return linkedAddresses;
    } catch (err) {
      addMessage('Get Linked Addresses', err.message || 'Error');
      return undefined;
    } finally {
      setIsLoading(false);
    }
  }, [passportClient, setIsLoading, addMessage]);

  const linkWallet = useCallback(
    async params => {
      setIsLoading(true);
      try {
        const linkedWallet = await passportClient.linkExternalWallet(params);
        addMessage('Link Wallet', linkedWallet);
        return linkedWallet;
      } catch (e) {
        addMessage(`Link wallet failed: message: ${e.message} type: ${e.type}`);
        return undefined;
      } finally {
        setIsLoading(false);
      }
    },
    [passportClient, setIsLoading, addMessage],
  );

  const logout = useCallback(async () => {
    setIsLoading(true);
    try {
      await passportClient.logout();
      setPassportClient(null);
      setImxProvider(undefined);
      setZkEvmProvider(undefined);
    } catch (err) {
      addMessage('Logout', err.message || 'Error');
    } finally {
      setIsLoading(false);
    }
  }, [setPassportClient, passportClient, setIsLoading, addMessage]);

  const getSigner = useCallback(() => {
    setIsLoading(true);
    try {
      const passportWallet = passportClient.connectEvm();
      const ethersProvider = new ethers.providers.Web3Provider(passportWallet);
      const signer = ethersProvider.getSigner();
      return signer;
    } catch (err) {
      return undefined;
    } finally {
      setIsLoading(false);
    }
  }, [passportClient, setIsLoading]);

  const login = useCallback(async () => {
    removeLocalStorage(STORAGE.TYPE_BOUNTY);
    try {
      setIsLoading(true);

      // Connect to EVM provider via passport client
      const passportProvider = await passportClient.connectEvm();
      const web3Provider = new ethers.providers.Web3Provider(passportProvider);

      // Request account from the web3 provider
      const [account] = await web3Provider.send('eth_requestAccounts', []);

      // Get ID token and user information from passport client
      const idToken = await passportClient.getIdToken();
      const userInfo = await getUserInfo();

      // Retrieve game URL from local storage
      const gameUrl = getLocalStorage(STORAGE.GAME_URL);

      // Prepare payload for authentication request
      const payload = {
        login_type: LOGIN_TYPE.IMMUTABLE,
        data: {
          id_token: idToken,
          ip_address: '',
          language_code: getLocalStorage(STORAGE.CURRENT_LANG) || 'en',
        },
      };

      // Get authentication code and perform login action
      const res = await auth.getAuthCode(payload);

      const { success } = res;
      if (!success) {
        throw new Error('Connect to server fail!');
      }
      auth?.setLoggedIn(true);
      await onLoginAction(res, account, userInfo, gameUrl);
      setLocalStorage(STORAGE.LOGIN_TYPE, LOGIN_TYPE.IMMUTABLE);
      // Add success message with account information
      addMessage('Login', account);
      return res;
    } catch (err) {
      // Add error message and log error to console
      addMessage('Login', err);
      return err;
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passportClient, setIsLoading, addMessage]);

  useEffect(() => {
    async function init() {
      setIsLoading(true);
      try {
        const provider = await passportClient.connectEvm();
        const accounts = await provider?.request({ method: 'eth_accounts' });
        const typeLogin = getLocalStorage(STORAGE.LOGIN_TYPE);
        if (accounts.length && typeLogin === LOGIN_TYPE.IMMUTABLE) {
          auth?.setLoggedIn(true);
        }
      } catch (err) {
      } finally {
        setIsLoading(false);
      }
    }
    init();
  }, [auth, passportClient, setIsLoading, login]);

  const providerValues = useMemo(
    () => ({
      imxProvider,
      zkEvmProvider,
      connectImx,
      connectZkEvm,
      logout,
      login,
      getIdToken,
      getAccessToken,
      getUserInfo,
      getLinkedAddresses,
      linkWallet,
      getSigner,
    }),
    [
      imxProvider,
      zkEvmProvider,
      connectImx,
      connectZkEvm,
      logout,
      login,
      getIdToken,
      getAccessToken,
      getUserInfo,
      getLinkedAddresses,
      linkWallet,
      getSigner,
    ],
  );

  return <PassportContext.Provider value={providerValues}>{children}</PassportContext.Provider>;
}

export function usePassportProvider() {
  return useContext(PassportContext);
}
