import { types } from 'mobx-state-tree';

import { Model } from '../utils/mobx-model-helper';
import { generateCode } from '../api/auth';

const TYPES = {
  GET_AUTH_CODE: 1,
};

const InitialData = types.model('InitialData').props({
  email: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  publicAddress: types.maybeNull(types.string),
});

const AuthStore = Model.named('AuthStore')
  .props({
    loggedIn: types.boolean,
    initialData: InitialData,
    isOpenLoginModal: types.boolean,
    isShowLoginModal: types.boolean,
    isOpenLoginImmutableModal: types.boolean,
    isOpenLoginDM2CModal: types.boolean,
  })
  .actions(self => ({
    toggleOpenModalLogin() {
      self.isOpenLoginModal = !self.isOpenLoginModal;
    },

    toggleShowModalLogin() {
      self.isShowLoginModal = !self.isShowLoginModal;
    },

    toggleOpenImmutableModalLogin() {
      self.isOpenLoginImmutableModal = !self.isOpenLoginImmutableModal;
    },

    toggleOpenDM2CModalLogin() {
      self.isOpenLoginDM2CModal = !self.isOpenLoginDM2CModal;
    },

    setLoggedIn(payload) {
      self.loggedIn = payload;
    },

    setInitialData(data) {
      Object.keys(data).forEach(key => {
        self.initialData[key] = data[key];
      });
    },

    getInitialData() {
      return self.initialData;
    },

    getAuthCode(payload) {
      return self.request({
        type: TYPES.GET_AUTH_CODE,
        api: generateCode,
        payload,
      });
    },
  }));

export { TYPES };
export default AuthStore.create({
  isOpenLoginModal: false,
  isShowLoginModal: true,
  isOpenLoginImmutableModal: false,
  isOpenLoginDM2CModal: false,
  loggedIn: false,
  initialData: {
    publicAddress: '',
  },
});
