export const SCOPE = 'openid offline_access profile email transact';
export const AUDIENCE = 'platform_api';
export const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI || '';
export const LOGOUT_REDIRECT_URI = process.env.REACT_APP_LOGOUT_REDIRECT_URI || '';
export const LOGOUT_MODE = process.env.REACT_APP_LOGOUT_MODE;
export const SILENT_LOGOUT_REDIRECT_URI = process.env.REACT_APP_SILENT_LOGOUT_REDIRECT_URI || '';
export const SILENT_LOGOUT_PARENT_URI = process.env.REACT_APP_SILENT_LOGOUT_PARENT_URI || '';
export const BASE_PATH = process.env.REACT_APP_BASE_PATH || '';
export const MARKETPLACE_FEE_RECIPIENT = '0x3082e7C88f1c8B4E24Be4a75dee018ad362d84d4';
export const MARKETPLACE_FEE_PERCENTAGE = 1;
export const IMMUTABLE_CLIENT_ID = process.env.REACT_APP_IMMUTABLE_CLIENT_ID || '';
export const IMMUTABLE_PUBLISHABLE_KEY = process.env.REACT_APP_IMMUTABLE_PUBLISHABLE_KEY || '' || '';
