import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Component, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import LoginDM2Modal from '../components/modal/modal-login-dm2';
import ModalToast from '../components/modal/modal-toast';
@withTranslation('common')
@withRouter
@inject(stores => ({
  authStore: stores.auth,
  categoriesStore: stores.categories,
}))
@observer
class Header extends Component {
  static propTypes = {
    authStore: PropTypes.object,
    location: PropTypes.object,
    categoriesStore: PropTypes.object,
  };

  state = {
    isOpenMenuPanel: false,
    isOpenUserPanel: false,
    isOpenSearchMobile: false,
    classFixedHeader: '',
    isOpenRegisterModal: false,
  };

  render() {
    const { authStore, t, modalToast, setModalToast } = this.props;
    const {
      toggleOpenDM2CModalLogin,
      isOpenLoginDM2CModal,
    } = authStore;

    return (
      <div className="header">
        <LoginDM2Modal onClose={toggleOpenDM2CModalLogin} isOpen={isOpenLoginDM2CModal} setModalToast={setModalToast} />
        <ModalToast
          isOpen={modalToast.isShow}
          description={modalToast?.description}
          type={modalToast.type}
          textSuccess={t('common:success')}
          textFail={t('common:fail')}
          onClose={() =>
            setModalToast({
              ...modalToast,
              isShow: false,
            })
          }
          onOk={() => {
            setModalToast({
              ...modalToast,
              isShow: false,
            });
            // modalToast && modalToast?.actionToast();
          }}
        />
      </div>
    );
  }
}

// export default Header;
export default withWeb3AuthHook(Header);

function withWeb3AuthHook(Component) {
  return function WrappedComponent(props) {
    
    const [modalToast, setModalToast] = useState({
      isShow: false,
      type: 'success',
      description: '',
    });
    return <Component {...props} modalToast={modalToast} setModalToast={setModalToast} />;
  };
}
